.margindiv {

  margin-left: 51%;
  margin-top: 160px;
}

.bg-cta {

  background-image: url("yamheru.jpg");
  background-size: cover;
  background-position: center;

}


.fixed-itresse-bottom{
  position: fixed;
  bottom: 0;
  width: 100%;
  z-index: 4;

}

.luz{
  background-color: rgba(255, 255, 255, 0.4);
}

a{
  text-decoration: none;
  color: black;

}

#contact{
  height: 90vh;
  border-bottom: 4px solid #B60D35;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

}

#contact h2{
  margin: 0;
}

.contact-details{
  transition: transform 0.3s ease-out;
}

.contact-details:hover {
  transform: translateY(10px);
}

.btn{
  display: inline-block;
  padding: 1rem 2rem;
  border-radius: 2px;
}



.fab{
  font-family: "Font Awesome 5 Brands";
}



#footer{

  margin: 10px auto;
}



.color-boton1{
  background-color: rgb(241, 148, 175);
}




@media only screen and (max-width: 480px) {

.rotateunii {

          width: auto;
            }
}
