.my-class{
  height: 160px !important;
  width: 160px;
  margin-right:65px;
  margin-top: 30px;
}



  .color-boton{
  	color: white;
    background-color: #FF6700;
    border-color: #FF6700;
  }


  .color-boton:hover{
    color: white;
    background-color: #539CE7;
    border-color: #539CE7;
    transition: all 0.4s ease-in-out;
    moz-transition: all .4s ease-in-out;
    -o-transition: all .4s ease-in-out;
    -webkit-transition: all .4s ease-in-out;
  }

.hover-sidebar{
  color:white
}

.hover-sidebar:hover{
  color:#FF6700;
  transition: all 0.4s ease-in-out;
  moz-transition: all .4s ease-in-out;
  -o-transition: all .4s ease-in-out;
  -webkit-transition: all .4s ease-in-out;
}



#page-wrap {
  text-align: left;

  /* Prevent sidebar from showing a scrollbar on page */
  overflow: auto;
}

#App {

  /* Give app full page to work with */
  height: 10vh;
}

/* Individual item */
.bm-item {
  display: inline-block;

  /* Our sidebar item styling */
  text-decoration: none;
  margin-bottom: 10px;
  color: #d1d1d1;
  transition: color 0.2s;

}

/* Change color on hover */
.bm-item:hover {
  color: white;
}

/* The rest copied directly from react-burger-menu docs */

/* Position and sizing of burger button */
.bm-burger-button {
  position: fixed;
  width: 60px ;
  height: 40px;
  top: 50px;
  right: 50px;
}

/* Color/shape of burger icon bars */
.bm-burger-bars {
  background: rgb(241, 148, 175);;
}

/* Position and sizing of clickable cross button */
.bm-cross-button {
  height: 60px !important;
  width: 60px !important;
  margin-right:45px;
  margin-top: 30px
}


/* Color/shape of close button cross */
.bm-cross {
  background: rgb(241, 148, 175);
  height: 60px !important;
  width: 10px !important;
  top: -5px;
  right: 10px;

}

/* General sidebar styles */
.bm-menu {
  background: #373a47;
  padding: 2.5em 1.5em 0;
  font-size: 1.15em;

}

/* Morph shape necessary with bubble or elastic */
.bm-morph-shape {
  fill: #373a47;
}

/* Wrapper for item list */
.bm-item-list {
  color: #b8b7ad;
}

/* Styling of overlay */
.bm-overlay {
  background: rgba(0, 0, 0, 0.3);
}

@media screen and (max-width: 30em) {

  .bm-burger-button {
    position: fixed;
    width: 30px;
    height: 20px;
    top: 25px;
    right: 25px;
  }

  .bm-cross-button {
    height: 30px !important;
    width: 30px !important;
    margin-right:10px;
    margin-top: 15px
  }

  .bm-cross {
    background: rgb(241, 148, 175);
    height: 30px !important;
    width: 5px !important;
    top: -5px;
    right: -1px;

  }

}

