

.fixed-itresse{
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 4;
}

